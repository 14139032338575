<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Database Center</h4>
        <date-picker
          v-model="month"
          value-type="format"
          type="month"
          :default-value="month"
          @change="changeDate"
        />
        <div>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mr-2"
            @click="makeBackup"
          >
            Make Backup
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="showModal"
          >
            Undo Backup
          </b-button>
        </div>

      </div>
      <b-table
        responsive="sm"
        :items="backups"
        :fields="fields"
        :per-page="perPage"
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        :current-page="currentPage"
        @row-clicked="showDetails"
      />
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="md"
        class="my-0"
      />
    </b-card>

    <b-modal
      id="restore-app-modal"
      title="Restore Backup"
      hide-footer
    >
      <b-card-text>
        <b-alert
          variant="warning"
          show
        >
          <div class="alert-body">
            Filename: {{ backup.name }}<br>
            Created at: {{ backup.date }}
          </div>

        </b-alert>
        <div class="mt-4 d-flex align-items-center justify-content-between">
          <div />
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            @click="restore"
          >
            Restore Backup
          </b-button>
        </div>

      </b-card-text>
    </b-modal>

    <b-modal
      id="app-modal"
      title="Restore Backup"
      hide-footer
    >
      <b-card-text>
        <h5 class="">
          Select Restore File
        </h5>
        <b-form-select
          v-model="selected"
          :options="options"
        />
        <div class="mt-4 d-flex align-items-center justify-content-between">
          <div />
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            @click="restoreBackup"
          >
            Undo Restore
          </b-button>
        </div>

      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BFormSelect, BAlert, BPagination
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import DatePicker from 'vue2-datepicker'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'vue2-datepicker/index.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BFormSelect,
    BAlert,
    BPagination,
    ToastificationContent,
    DatePicker,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      fields: [{ key: 'name', label: 'filename' }, { key: 'date', label: 'Backup Date', sortable: true }],
      selected: null,
      options: [
        { value: null, text: 'Please select restore file' },
      ],
      items: [],
      month: moment().format('Y-MM'),
      backup: {},
      filename: '',
      perPage: 20,
      pageOptions: [3, 5, 10],
      currentPage: 1,
      filter: null,
      sortBy: 'id',
      sortDesc: false,
      filterOn: [],
    }
  },
  computed: {
    backups() {
      return this.$store.state.backup.backups
    },
    totalRows() {
      return this.backups.length
    },
  },
  mounted() {
    this.getBackups()
  },
  methods: {
    showModal() {
      this.$bvModal.show('app-modal')
    },
    getBackups(date = moment().format('MM-Y')) {
      this.$store.dispatch('backup/getAllBackups', date)
    },
    hide() {
      this.$bvModal.hide('app-modal')
    },
    showDetails(item) {
      this.is_update = true
      this.backup = item
      const name = item.name.split('.')
      // eslint-disable-next-line prefer-destructuring
      this.filename = name[0]
      this.$bvModal.show('restore-app-modal')
    },
    makeBackup() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You want to backup database!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Create Backup',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const date = moment().format('MM-Y')
          this.$store.dispatch('backup/makeBackups', date)
        } else if (result.dismiss === 'cancel') {
          // this.$swal({
          //   title: 'Cancelled',
          //   text: 'Your imaginary file is safe :)',
          //   icon: 'error',
          //   customClass: {
          //     confirmButton: 'btn btn-success',
          //   },
          // })
        }
      })
    },
    restoreBackup() {
      if (this.selected === undefined || this.selected === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'AlertTriangleIcon',
            text: 'Please select restore file',
            variant: 'danger',
          },
        })
      } else {
        this.$store.dispatch('backup/restoreBackups', this.selected)
      }
    },
    restore() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You want to restore backup!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Restore Backup',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('backup/restoreBackups', { date: moment().format('MM-Y'), name: this.filename })
        } else if (result.dismiss === 'cancel') {
          // this.$swal({
          //   title: 'Cancelled',
          //   text: 'Your imaginary file is safe :)',
          //   icon: 'error',
          //   customClass: {
          //     confirmButton: 'btn btn-success',
          //   },
          // })
        }
      })
    },
    changeDate() {
      const date = moment(this.month).format('MM-Y')
      this.getBackups(date)
    },
  },
}
</script>

<style>

</style>
